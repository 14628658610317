"use strict";

var _interopRequireDefault = require("D:/www/erp/back-end/client-vue2/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _operation = require("@/api/operation");
var _pageFilter = _interopRequireDefault(require("./pageFilter"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Log',
  components: {
    pageFilter: _pageFilter.default
  },
  filters: {},
  data: function data() {
    return {
      tableKey: 0,
      tableData: {
        data: [],
        total: 0
      },
      tableLoading: true,
      tableFilter: {
        page: 1,
        per_page: 15
      }
    };
  },
  created: function created() {
    this.fetchList();
  },
  methods: {
    fetchList: function fetchList() {
      var _this = this;
      this.tableLoading = true;
      (0, _operation.operation)(this.tableFilter).then(function (response) {
        _this.tableData = response.data;
        _this.tableLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.tableFilter.page = 1;
      this.fetchList();
    },
    handleFilterClear: function handleFilterClear() {
      this.tableFilter = {
        page: 1,
        per_page: 15
      };
      this.fetchList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFilter.per_page = val;
      this.fetchList();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.tableFilter.page = val;
      this.fetchList();
    }
  }
};
exports.default = _default;