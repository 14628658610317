var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c("form-create", {
        attrs: { rule: _vm.form.fields, option: _vm.formOption },
        on: {
          mounted: function ($event) {
            return _vm.formMounted($event)
          },
        },
      }),
      _c(
        "div",
        { staticClass: "flex flex-auto" },
        [
          _c(
            "el-tabs",
            { attrs: { "tab-position": "left" } },
            _vm._l(_vm.form.groups, function (group) {
              return _c(
                "el-tab-pane",
                { key: group.title, attrs: { label: group.title } },
                [
                  _c("form-create", {
                    attrs: { rule: group.fields, option: _vm.formOption },
                    on: {
                      mounted: function ($event) {
                        return _vm.formMounted($event)
                      },
                    },
                  }),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "flex justify-center" },
        [
          _c(
            "el-button",
            {
              directives: [{ name: "waves", rawName: "v-waves" }],
              attrs: {
                type: "success",
                icon: "el-icon-upload",
                loading: _vm.formLoading,
              },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v("提交保存")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }